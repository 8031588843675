<template>
  <div>
    <section class="d-flex my-30">
      <div class="circle center">1</div>
      <div>
       {{$t('faq.signup.one')}}
        <a href="https://www.securmeet.com/register"
          >https://www.securmeet.com/register</a
        >
      </div>
    </section>
    <img src="/img/static/getStarted.png" alt="" />
    <section class="d-flex my-30">
      <div class="circle center">2</div>
      <div>
         {{$t('faq.signup.two')}}
      </div>
    </section>
    <img src="/img/static/fillOutForm.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.circle {
  background-color: #409875;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  font-size: 14px;
  margin-right: 20px;
}

a {
  color: #409875;
}

img {
  width: 100%;
  object-fit: contain;
}
</style>