<template>
  <div>
    <section class="d-flex my-30">
      <div class="circle center">1</div>
      <div>
      {{$t('faq.howToUse.one')}}
      </div>
    </section>
    <img src="/img/static/howToUse1.png" alt="" />
    <section class="d-flex my-30">
      <div class="circle center">2</div>
      <div>  {{$t('faq.howToUse.two')}}</div>
    </section>
    <img src="/img/static/howToUse2.png" alt="" />
    <section class="d-flex my-30">
      <div class="circle center">3</div>
      <div>
        {{$t('faq.howToUse.three')}}
      </div>
    </section>
    <section class="d-flex my-30">
      <div class="circle center">4</div>
      <div>
        {{$t('faq.howToUse.four')}}
      </div>
    </section>
    <img src="/img/static/howToUse3.png" alt="" />
    <section class="d-flex my-30">
      <div class="circle center">5</div>
      <div>
         {{$t('faq.howToUse.five')}}
      </div>
    </section>
    <section class="d-flex my-30">
      <div class="circle center">6</div>
      <div>
         {{$t('faq.howToUse.six')}}
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.circle {
  background-color: #409875;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  font-size: 14px;
  margin-right: 20px;
}

a {
  color: #409875;
}

img {
  width: 100%;
  object-fit: contain;
}
</style>