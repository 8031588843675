<template>
  <div class="w100 view_container">
    <section class="header text-center center d-col px-50">
      <h1 class="weight-300 font-48">{{ $t("faq.title") }}</h1>
      <p>{{ $t("faq.subtitle") }}</p>
    </section>
    <section class="faqContainer center my-10 mx-20 b-box">
      <div class="shadow max">
        <div v-for="(item, index) in text" :key="index">
          <div class="item pointer pa-30" @click="toggle(index)">
            {{ item.q }}
          </div>
          <div class="faqdropdown w100 b-box" :class="'faqdropdown' + index">
            <div class="pa-30">
              <div>{{ item.a }}</div>
              <HowToUse v-if="index == 1" class="mt-50" />
              <Signup v-if="index == 8" class="mt-50" />
            </div>
          </div>
          <hr class="hr mx-0" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { gsap } from "gsap";
import Signup from "./Signup.vue";
import HowToUse from "./HowToUse.vue";

export default {
  components: { Signup, HowToUse },
  methods: {
    toggle(q) {
      let el = document.getElementsByClassName("faqdropdown" + q);
      el = el[0];
      if (el.clientHeight == 0) {
        gsap.to(el, {
          scaleY: 1,
          height: "auto",
          duration: 0.2,
          opacity: 1,
          ease: "power3.out",
          transformOrigin: "top",
        });
      } else {
        gsap.to(el, {
          scaleY: 0,
          height: 0,
          opacity: 0,
          duration: 0.2,
          ease: "power3.out",
          transformOrigin: "top",
        });
      }
    },
  },
  computed: {
    text() {
      return [
        { q: this.$t("faq.q1"), a: this.$t("faq.a1") },
        { q: this.$t("faq.q2"), a: this.$t("faq.a2") },
        { q: this.$t("faq.q3"), a: this.$t("faq.a3") },
        { q: this.$t("faq.q4"), a: this.$t("faq.a4") },
        { q: this.$t("faq.q5"), a: this.$t("faq.a5") },
        { q: this.$t("faq.q6"), a: this.$t("faq.a6") },
        { q: this.$t("faq.q7"), a: this.$t("faq.a7") },
        { q: this.$t("faq.q8"), a: this.$t("faq.a8") },
        { q: this.$t("faq.q9"), a: this.$t("faq.a9") },
        { q: this.$t("faq.q10"), a: this.$t("faq.a10") },
        { q: this.$t("faq.q11"), a: this.$t("faq.a11") },
      ];
    },
  },
};
</script>

<style scoped>
section {
  box-sizing: border-box;
}

.header {
  width: 100%;
  height: 300px;
  background-color: #24394b;
}

.faqdropdown {
  background-color: #141925;
  height: 0;
  opacity: 0;
  transform: scaleY(0);
}

.max {
  max-width: 900px;
}

@media only screen and (max-width: 576px) {
  .faqContainer {
    margin-left: 0px;
    margin-right: 0px;
  }

  .header {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>