import { render, staticRenderFns } from "./HowToUse.vue?vue&type=template&id=f391746a&scoped=true&"
import script from "./HowToUse.vue?vue&type=script&lang=js&"
export * from "./HowToUse.vue?vue&type=script&lang=js&"
import style0 from "./HowToUse.vue?vue&type=style&index=0&id=f391746a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f391746a",
  null
  
)

export default component.exports